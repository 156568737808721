import React, { useState, useEffect } from "react";

// Bootstrap imports
import { Container, Col, Row, Dropdown, DropdownButton, Button } from "react-bootstrap";

// Import NFL spreads JSON
import nflSpreads from "../NFLPredictor/nflSpreads.json";

// Styling

export default function NFLPredictor() {
  useEffect(() => {
    document.title = "WSA";
    const mediaQuery = window.matchMedia("(max-width: 1000px)");
    setIsMobile(mediaQuery.matches);
    const handleMediaQueryChange = (e) => setIsMobile(e.matches);
    mediaQuery.addListener(handleMediaQueryChange);
    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const [predictedSpread, setPredictedSpread] = useState(null);
  const [showSpread, setShowSpread] = useState(false);

  const nflTeams = Object.keys(nflSpreads); // List of NFL teams

  const teamLogos = {
    "Detroit Lions": "det",
    "Chicago Bears": "chi",
    "Minnesota Vikings": "min",
    "Green Bay Packers": "gb",
    "New York Giants": "nyg",
    "Philadelphia Eagles": "phi",
    "Dallas Cowboys": "dal",
    "Washington Commanders": "was",
    "San Francisco 49ers": "sf",
    "Seattle Seahawks": "sea",
    "Los Angeles Rams": "lar",
    "Arizona Cardinals": "ari",
    "Tampa Bay Buccaneers": "tb",
    "New Orleans Saints": "no",
    "Carolina Panthers": "car",
    "Atlanta Falcons": "atl",
    "Kansas City Chiefs": "kc",
    "Los Angeles Chargers": "lac",
    "Denver Broncos": "den",
    "Las Vegas Raiders": "lv",
    "Buffalo Bills": "buf",
    "Miami Dolphins": "mia",
    "New England Patriots": "ne",
    "New York Jets": "nyj",
    "Baltimore Ravens": "bal",
    "Cincinnati Bengals": "cin",
    "Cleveland Browns": "cle",
    "Pittsburgh Steelers": "pit",
    "Tennessee Titans": "ten",
    "Indianapolis Colts": "ind",
    "Jacksonville Jaguars": "jax",
    "Houston Texans": "hou"
  };

  const handleHomeTeamSelect = (team) => {
    setHomeTeam(team);
    setPredictedSpread(null);
    setShowSpread(false);
  };

  const handleAwayTeamSelect = (team) => {
    setAwayTeam(team);
    setShowSpread(false);
  };

  const handleSubmit = () => {
    if (homeTeam && awayTeam && nflSpreads[homeTeam][awayTeam] !== undefined) {
      const spread = nflSpreads[homeTeam][awayTeam];
      if (spread < 0) {
        setPredictedSpread(`The ${homeTeam} win by ${Math.abs(spread)} against The ${awayTeam}`);
      } else if (spread > 0) {
        setPredictedSpread(`The ${homeTeam} lose by ${spread} against The ${awayTeam}`);
      } else {
        setPredictedSpread(`The ${homeTeam} tie against The ${awayTeam}`);
      }
    } else {
      setPredictedSpread("No data available");
    }
    setShowSpread(true);
  };

  return (
    <div className="home-page">
      <div className="bracket-background" style={{ marginBottom: "20px" }}>
        <Container className="main">
          <Row>
            <div className="projects-header">
              <h1>NFL Matchup Predictor 🏈</h1>
              <h6>
                This tool predicts the outcome of NFL matchups based on advanced machine learning and deep neural networks. By analyzing historical data, team performance, and more, it calculates the likely outcome of each game, providing a predicted spread for the home team.
              </h6>
            </div>
          </Row>

          {/* Row for home and away dropdown buttons */}
          <Row style={{ marginTop: "20px" }} className="d-flex justify-content-center align-items-center">
            <Col md="auto" className="mx-2">
              <DropdownButton
                id="home-team-dropdown"
                className="team-dropdown"
                title={homeTeam || "Select Home Team"}
                onSelect={handleHomeTeamSelect}
                style={{ fontSize: "1.2rem", padding: "10px" }}
              >
                {nflTeams.map((team, index) => (
                  <Dropdown.Item key={index} eventKey={team}>
                    <img
                      src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/${teamLogos[team]}.png&h=50&w=50`}
                      alt={`${team} logo`}
                      style={{ marginRight: "10px" }}
                    />
                    {team}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
            <Col md="auto" className="mx-2">
              <DropdownButton
                id="away-team-dropdown"
                className="team-dropdown"
                title={awayTeam || "Select Away Team"}
                onSelect={handleAwayTeamSelect}
                style={{ fontSize: "1.2rem", padding: "10px" }}
              >
                {nflTeams.map((team, index) => (
                  <Dropdown.Item key={index} eventKey={team}>
                    <img
                      src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/${teamLogos[team]}.png&h=50&w=50`}
                      alt={`${team} logo`}
                      style={{ marginRight: "10px" }}
                    />
                    {team}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>

          {/* Row for the simulate button */}
          <Row style={{ marginTop: "20px" }} className="d-flex justify-content-center">
            <Col md="auto">
              <Button onClick={handleSubmit} disabled={!homeTeam || !awayTeam} style={{ fontSize: "1.1rem" }}>
                Simulate
              </Button>
            </Col>
          </Row>

          {/* Show team logos with VS text */}
          {homeTeam && awayTeam && (
            <Row style={{ marginTop: "20px" }} className="d-flex justify-content-center align-items-center">
              <Col md="auto" className="text-center">
                <img
                  src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/${teamLogos[homeTeam]}.png&h=150&w=150`}
                  alt={`${homeTeam} logo`}
                  style={{
                    width: "70%",
                    height: "70%",
                    objectFit: "contain",
                    transition: "transform 0.3s ease"
                  }}
                  className="team-logo-hover"
                />
              </Col>
              <Col md="auto" className="text-center">
                <h2 style={{ margin: "0 10px" }}>vs</h2>
              </Col>
              <Col md="auto" className="text-center">
                <img
                  src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/${teamLogos[awayTeam]}.png&h=150&w=150`}
                  alt={`${awayTeam} logo`}
                  style={{
                    width: "70%",
                    height: "70%",
                    objectFit: "contain",
                    transition: "transform 0.3s ease"
                  }}
                  className="team-logo-hover"
                />
              </Col>
            </Row>
          )}

          {showSpread && predictedSpread !== null && (
            <Row style={{ marginTop: "20px" }} className="d-flex justify-content-center">
              <Col md={6} className="text-center">
                <div className="predicted-spread-box">
                  <h3>
                    {predictedSpread !== "No data available" ? predictedSpread : "No data available"}
                  </h3>
                </div>
              </Col>
            </Row>
          )}
        </Container>
        <style>{`
          .team-dropdown .dropdown-menu {
            max-height: 333px;
            overflow-y: auto;
          }
        `}</style>
      </div>
    </div>
  );
}
