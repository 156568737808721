// Image imports
import Basketball from "../images/wbb/basketball.jpeg";
import Chart from "../images/daily-fantasy/fantasy-chart.png";
import Football from "../images/football/football.jpeg";
import Hockey from "../images/hockey/hockey.jpeg";
import Snowboard from "../images/idx/snowboard.PNG";
import Soccer from "../images/soccer/soccer_transfer.jpg";
import MarchMadness from "../images/march-madness/march_madness.jpg";
import HockeyCard2023 from "../images/hockey/player-card/hockey.png";
import NBA2023 from "../images/basketball/steph-curry.png"
import NFL2023 from "../images/football/nfl.jpg";
import HockeyProj2023 from "../images/hockey/player-projection/ushl-to-ncaa.png";
import NFLW24 from "../images/w24-projects/nfl_ingame_win_predictor_cover.png";
import CricketW24 from "../images/w24-projects/cricket_cover.jpeg";
import MarchMadnessW24 from "../images/w24-projects/march_madness_w24_cover.webp";
import HockeyFAW24 from "../images/w24-projects/nhl_fa_cover.jpg";
import Euros from "../images/w24-projects/euros_cover.jpg";
import NBAShooting from "../images/w24-projects/nba_shooting_cover.jpg";
import NFLDraft from "../images/w24-projects/nfldraft_cover.jpg"
import F1 from "../images/f24-projects/f1_cover.jpeg";
import NBAStatsPredictor from "../images/f24-projects/nba_stats_predictor_cover.jpg";
import CFBTransfer from "../images/f24-projects/cfb_transfer_cover.png";
import CHASM from "../images/f24-projects/chasm_cover.jpeg";

const projects = [
  {
    title: "College Hoops Analytical Spread Model (CHASM)",
    text: [`In Fall 2024, the CBB project team created a dense neural network to predict the spread of college basketball games. 
    Training data was scraped from publicly available stats websites, generating a dataset of over 50,000 game results spanning 
    a decade of Division I men’s basketball. The model correctly predicts the winner of games with over 75% accuracy, using over 
    100 metrics to output a projection for the point differential between the two teams.`],
    image: CHASM,
    season: "Fall 2024",
    route: "chasm",
    featured: true,
  },
  {
    title: "F1 Pure Skill Index",
    text: [`In Formula 1, race results and championship wins are often heavily influenced by car performance, making 
    it difficult to measure a driver's true skill. Even an average driver in a dominant car can win championships, 
    while great drivers in slower cars may never get the recognition they deserve. This project aimed to answer a 
    fundamental question: who is the best F1 driver if all cars were equal? To find out, we focused on the only fair 
    comparison available — teammates racing in the same machinery — and developed an ELO-based ranking system to measure 
    driver skill without car performance bias.`],
    image: F1,
    season: "Fall 2024",
    route: "f1-pure-skill-index",
    featured: false,
  },
  {
    title: "NBA Stats Predictor",
    text: [`In the Fall 2024 semester, we focused on building a predictive model to estimate NBA player performance, 
    using minutes played as the primary input. By analyzing NBA players statistics from the 2024-2025 season, 
    including points, assists, and rebounds we developed a model to predict each player's predicted statline based on 
    the inputted minutes.`],
    image: NBAStatsPredictor,
    season: "Fall 2024",
    route: "nba-stats-predictor",
    featured: false,
  },
  {
    title: "College Football Transfer Analysis",
    text: [`The college football transfer portal has been the topic of many debates and discussions over the recent seasons. 
    However, with how quickly it has expanded, the true impact of the portal has yet to be studied on a nationwide basis. 
    This project set out to analyze just how impactful the transfer portal actually is to the average team, as well as what 
    stats could be used as predictors of positive or negative impacts, in order to provide insight to coaches and fans of the game.`],
    image: CFBTransfer,
    season: "Fall 2024",
    route: "cfb-transfer",
    featured: false,
  },
  {
    title: "2024 NFL Draft Predictor",
    text: [`During the Spring 2024 Semester, our project team embarked on a comprehensive analysis of the NFL Draft 
    prospects, focusing specifically on quarterbacks and receivers. Our objective was to develop a predictive model 
    that accurately ranked these players based on a combination of college statistics, measurable attributes, 
    combine results, and professional scouting analytics. To construct our model, we web scraped data from a multitude 
    of sources, including college statistics databases, combine results, and scouting reports (Pro Football Focus, 
    ESPN, Daniel Jeremiah, etc).`],
    image: NFLDraft,
    season: "Winter 2024",
    route: "nfl-draft-predictor",
    featured: false,
  },
  {
    title: "NFL In-Game Win Predictor",
    text: [`In Winter 2024, we created a model to calculate NFL teams' win probability at a given moment of any game. 
    The model utilized the statistics of both teams in the game as well as the current game situation 
    to predict an expected point total for each team, which was translated into a win probability. 
    The model also allowed comparing play-calling decisions for specific game states by enabling calculating 
    the difference in win probability between the two plays.`],
    image: NFLW24,
    season: "Winter 2024",
    route: "nfl-ingame-win-predictor",
    featured: true,
  },
  {
    title: "ICC Men's T20 World Cup Simulator",
    text: [`In the winter 2024 semester, we developed a simulation program to predict the results of the 
      upcoming T20 Cricket World Cup based on match data from the past four years. Utilizing the current 
      T20I team rankings, and calculating batting and bowling statistics on a per-player basis, 
      we simulated the tournament hundreds of times to obtain overall predictions for each team's placement. 
      By deciphering and incorporating the differences in player strength among the 20 teams into our model, 
      we aimed to make accurate and informed predictions for the 2024 World Cup.`],
    image: CricketW24,
    season: "Winter 2024",
    route: "cricket-wc-simulator",
    featured: true,
  },
  {
    title: "2024 March Madness Bracket Predictor",
    text: [`For our project in Winter 2024, we wanted to try to predict the average number of wins for each team 
      in this year's March Madness tournament, with the hopes of making a bracket that performed as well as possible. 
      Although we know a lot more goes into matchups than just purely statistics of teams, we were curious to see 
      how condensing every meaningful stat for each team and assigning a value to a team would correlate with 
      said teams success in the tournament. To get to this value, we found the statistics for every tournament 
      team from 2007 onwards, looked at how successful those teams were, and then looked at the statistics they 
      were strong in. We then used various statistical tools to combine these stats into projected win totals.`],
    image: MarchMadnessW24,
    season: "Winter 2024",
    route: "march-madness-w24-predictor",
    featured: false,
  },
  {
    title: "NHL Free Agency Analysis",
    text: [`In Winter 2024, we evaluated which big free agent each NHL team should sign next season. We leveraged Python's 
      BeautifulSoup and SQL to scrape data from tables online with team names, cap spaces, and rosters. We then found a 
      "Player Score" stat, measuring individual production. The averages of each teams' player scores for each position 
      were used to determine the most needed position. We recommended a player from the free agent market that matched that 
      position and that the team could afford.`],
    image: HockeyFAW24,
    season: "Winter 2024",
    route: "nhl-free-agency",
    featured: false,
  },
  {
    title: "UEFA Euro 2024 Predictor",
    text: [`In the Winter 2024 Semester, we decided to create a machine-learning model that successfully predicted the winner 
      of the UEFA 2024 European Championship coming up this June. By aggregating dozens of metrics from multiple previous 
      competitions for each country, we used a Random Forest Classifier to use these statistics to simulate full tournaments 
      to determine the next European Champion.`],
    image: Euros,
    season: "Winter 2024",
    route: "euro2024-predictor",
    featured: false,
  },
  {
    title: "NBA Shooting Analysis",
    text: [`In the winter of 2024, we developed a project to analyze the shooting statistics that most lead to wins. 
      Web scraping data from the last full NBA regular season, we pulled team shooting statistics and sought correlation 
      with overall numbers like winning percentage and offensive efficiency to find the biggest factors for success. 
      Using these results, we are able to recommend the offensive strategy and personnel that teams should strive for.`],
    image: NBAShooting,
    season: "Winter 2024",
    route: "nba-shooting",
    featured: false,
  },
  {
    title: "NCAA Hockey Player Projections",
    text: [`During the Fall 2023 Semester, two of our project teams had the opportunity to work with 
    Michigan’s Men’s Hockey Varsity Team with the purpose of creating useful models in order to easier 
    evaluate players. The main goal of this project was to create an equivalency rating that 
    estimated how a player playing in the USHL, the premier junior hockey league in the United States, 
    would translate to the NCAA Hockey league.`],
    image: HockeyProj2023,
    season: "Fall 2023",
    route: "hockey-player-projections",
    featured: false,
  },
  {
    title: "NFL Game Predictor",
    text: [`In the 2023 Fall, we developed a machine learning model that generates an accurate betting 
    spread for any NFL game. Using hundreds of metrics to create a comprehensive team profile of every NFL 
    franchise since the 2016 season, we trained a neural network model to successfully produce realistic output 
    for any NFL game. Overall, this project showcases the power of machine learning in predicting NFL games and 
    its potential to offer valuable insights into projected standings and other predictive metrics.`],
    image: NFL2023,
    season: "Fall 2023",
    route: "nfl-game-predictor",
    featured: false,
  },
  {
    title: "NBA Team Matchup Simulator",
    text: [`During 2023 Fall, we worked on creating a NBA Team Matchup Simulator. The goal of the project 
    was to predict the outcomes of NBA games. Throughout the semester we would scrape data from Basketball 
    Reference and the NBA website. Leveraging the principles of Monte Carlo simulation, our approach then 
    involved randomizing play selections based on player data, helping us simulate games and thus predict 
    team matchups.`],
    image: NBA2023,
    season: "Fall 2023",
    route: "nba-matchup-simulator",
    featured: true,
  },
  {
    title: "Michigan Hockey Player Cards",
    text: [`In fall of 2023, we continued to work with the Michigan Men’s Hockey team by developing 
    a model to predict a player’s production, offense, defense, and transition scores. Depending on their 
    position, those scores were then weighted differently to combine to an overall player score. The xgBoost 
    model was based on certain stats for each score, and trained on their expected goals for and against 
    while on the ice.`],
    image: HockeyCard2023,
    season: "Fall 2023",
    route: "hockey-player-cards",
    featured: true,
  },
  {
    title: "NCAA March Madness Bracket Predictor",
    text: [`In the 2023 winter, we worked on creating power rankings for each NCAA Division 1 Men's Basketball team 
    based on their statistics from the season. We generated a bracket prediction for the 2023 NCAA 
    March Madness tournament and tested it against our club members' brackets. Using TensorFlow and other 
    Python libraries, we created a sequential, deep neural network optimized through the adjustment of a 
    variety of hyperparameters that power-ranked the teams based on their postseason potential. Check out 
    our predictions against the actual bracket in the Featured page!`],
    image: MarchMadness,
    season: "Winter 2023",
    route: "march-madness",
    featured: true,
  },
  {
    title: "Soccer Transfer Market Analysis",
    text: [`Started in winter 2023 and updated in winter 2024, we worked on analyzing the professional soccer 
      transfer market within the Premier League over the last 5 seasons. For each player in the EPL in those seasons, 
      we determined a player's value based on a variety of simple and advanced statistics, with weights based on the 
      importance of a statistic to a player's position. From there, we aggregated scores for each team and determined 
      how much value teams gained from the transfer market relative to their money spent. Finally, we ranked the top 
      transfers, loans, and free transfers for each season based on the individual player's value per euro spent.`],
    image: Soccer,
    season: "Winter 2023",
    route: "soccer-transfer-market",
    featured: false,
  },
  {
    title: "idX Ski & Snowboard Power Rankings",
    text: [`In 2022, we collaborated with Infinite Degrees (idX) to develop a power rankings system for 
          professional ski and snowboard athletes. Using data scraped from competition results and websites, 
          we generated a score for each professional athlete based on different trick components worth 
          different values with FIS scoring guidelines.`],
    image: Snowboard,
    season: "2017 - 2022",
    route: "idx-power-rankings",
    featured: false,
  },
  {
    title: "Michigan Hockey Project",
    text: [`In 2021, we initiated a project with the Michigan Men's Hockey Team. 
            We have built out a comprehensive hockey database with webscraping tools. 
            Using this data, we analyzed relationships in the stats with model testing. 
            We are currently developing a website that can display insights into our findings 
            using graphs and charts that will give the Michigan Men's Hockey team an advantage on the field.`],
    image: Hockey,
    season: "2017 - 2022",
    route: "michigan-hockey",
    featured: false,
  },
  {
    title: "Michigan WBB Win Predictor",
    text: [`In 2019, we undertook an analytics project with the Michigan Women’s Basketball Team. 
          We developed an interactive Excel application to allow managers, players, or coaches to easily 
          spot the strengths and weaknesses of opposing teams in addition to individual players. 
          The application was based on our logistic regression algorithm that determined winning strategies 
          against a particular Big Ten women’s basketball team.`],
    image: Basketball,
    season: "2017 - 2022",
    route: "michigan-wbb",
    featured: false,
  },
  {
    title: "Michigan Football Recruiting",
    text: [`In 2018, Wolverine Sports Analytics had the opportunity to work with the Michigan Football 
          Team to try and discover a potential recruiting edge that they could adopt. Under the guidance 
          of J.T. Rogan, former Director of Communications & Operations for the Head Coach, we created an 
          algorithm for predicting the likelihood of a recruit committing to Michigan using a brand new 
          analytical approach.`],
    image: Football,
    season: "2017 - 2022",
    route: "michigan-football",
    featured: false,
  },
  {
    title: "FanDuel Lineup Generator",
    text: [`In 2017, we created an advanced data pipeline and system of algorithms for optimizing NBA 
          daily fantasy competition submissions on platforms like FanDuel and DraftKings. 
          The architecture behind this project consisted of locating data sources, retrieving data, 
          storing data, preparing and cleaning data, developing models and algorithms, backtesting 
          results, scheduling cron jobs, deploying models, and creating salary-optimized lineups.`],
    image: Chart,
    season: "2017 - 2022",
    route: "daily-fantasy",
    featured: false,
  },
];

export default projects;
