import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";

// Import predictor components
import MarchMadnessPredictor from "../components/featured/MarchMadnessPredictor";
import NFLPredictor from "../components/featured/NFLPredictor";

export default function Featured() {
  // Set default to NFLPredictor so that the matchup simulator shows on page load
  const [selectedComponent, setSelectedComponent] = useState("NFLPredictor");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    document.title = "WSA";
    const mediaQuery = window.matchMedia("(max-width: 1000px)");
    setIsMobile(mediaQuery.matches);
    const handleMediaQueryChange = (e) => setIsMobile(e.matches);
    mediaQuery.addListener(handleMediaQueryChange);
    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  // Function to return button styles based on whether it's selected or not
  const buttonStyle = (componentName) => ({
    backgroundColor: selectedComponent === componentName ? "#007bff" : "#f8f9fa",
    color: selectedComponent === componentName ? "#fff" : "#000",
    border: "1px solid #007bff",
    padding: "10px 20px",
    margin: "0 10px",
    borderRadius: "5px",
    cursor: "pointer"
  });

  return (
    <div className="home-page">
      <Container className="main">
        <Row>
          <div className="projects-header">
            <h1>Featured Projects</h1>
            <h6>Choose a project to see our predictions!</h6>
          </div>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <button
            style={buttonStyle("NFLPredictor")}
            onClick={() => setSelectedComponent("NFLPredictor")}
          >
            NFL 2024 Matchup Simulator
          </button>
          <button
            style={buttonStyle("MarchMadnessPredictor")}
            onClick={() => setSelectedComponent("MarchMadnessPredictor")}
          >
            March Madness Bracket Predictor
          </button>
        </Row>
        <Row>
          {selectedComponent === "NFLPredictor" && <NFLPredictor />}
          {selectedComponent === "MarchMadnessPredictor" && <MarchMadnessPredictor />}
        </Row>
      </Container>
    </div>
  );
}
