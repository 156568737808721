import React from "react";
import { Bracket, Seed, SeedItem, SeedTeam } from 'react-brackets';
import { rounds, seeds } from "../../constants/brackets";

const CustomSeed = ({seed, breakpoint, year, region}) => {
    // breakpoint passed to Bracket component
    // to check if mobile view is triggered or not

    // mobileBreakpoint is required to be passed down to a seed

    //TODO: Figure out how to get year and region in here
    return (
        <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12 }}>
        <SeedItem>
            <div>
            <SeedTeam style={{ backgroundColor: (seed.teams[0]?.prediction == false) ? '#FF4A4A' : '#1a1d2e' }}>
                [{seeds[year][region][seed.teams[0]?.name]}] {seed.teams[0]?.name || '--------'}
            </SeedTeam>
            {seed.teams[1]?.name && 
                <SeedTeam style={{ backgroundColor: (seed.teams[1]?.prediction == false) ? '#FF4A4A' : '#1a1d2e' }}>
                    [{seeds[year][region][seed.teams[1]?.name]}] {seed.teams[1].name}
                </SeedTeam>
            }
            </div>
        </SeedItem>
        </Seed>
    );
};

const MarchMadnessBracket = ({year, region}) => {

    return (
        <Bracket 
            rounds={rounds[year][region]} 
            roundTitleComponent={(title) => {
                return <div style={{ textAlign: 'center', color: 'black' }}>{title}</div>;
            }}
            renderSeedComponent={(props) => <CustomSeed {...props} year={year} region={region} />}
            swipeableProps={{ enableMouseEvents: true, animateHeight: true }}
        />
    );
};


export default MarchMadnessBracket;
